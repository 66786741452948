import { useState, useEffect } from 'react';
import { useContext } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useDispatch ,useSelector,} from 'react-redux';
// import {
//   selectContacts,
//   selectBusiness,
//   selectContactsFetchStatus,
//   getFetchContactFieldsStatus,
//   selectBusinessFetchStatus,
// } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import { TableBody } from '@mui/material';
import { Contact } from './components';

import { fetchCampaigns, selectCampaigns, selectCampaignStatus  } from 'redux/features/campaignSlice/campaignSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

import campaignSliceService from 'redux/features/campaignSlice/campaignSliceService'
import { ICampaign } from "@trii/types/dist/Campaigns";

import { selectFilterStatus } from 'redux/features/campaignSlice/campaignSlice';
import { LoadingAppliedFilters } from '../../../../../SidebarFilter/components';

const Body = () => {
  // const { mode } = useContext(ContactBusinessTableContext);
  // const columnsFetchStatus = useSelector(getFetchContactFieldsStatus);
  // const itemsFetchStatus = useSelector(
  //   mode === 'contact' ? selectContactsFetchStatus : selectBusinessFetchStatus
  // );

  // const items =
  //   mode === 'contact' ? useSelector(selectContacts) : useSelector(selectBusiness);

  const dispatch = useAppDispatch();  // Obtenemos la función dispatch de Redux
  const campaigns = useSelector(selectCampaigns);  // Seleccionamos las campañas del estado global
  const status = useSelector(selectCampaignStatus);  // Seleccionamos el estado (loading, succeeded, etc.)
  const filterStatus = useSelector(selectFilterStatus)

  useEffect(() => {
    if (status.campaigns === 'idle') {
      dispatch(fetchCampaigns(filterStatus));  
    }
  }, [dispatch, status.campaigns, filterStatus]);  

  if (status.campaigns === 'loading') {
    return <LoadingAppliedFilters />;
  }

  if (status.campaigns === 'rejected') {
    return <div>Error al cargar las campañas.</div>;  
  }

  // const Items = <Contact />;

  return (
    <TableBody>
      {campaigns.map((campaign) => (
        <Contact key={campaign.id} campaign={campaign} /> 
      ))}
    </TableBody>
  );
};
export default Body;
