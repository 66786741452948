import { Box, Button, ToggleButtonGroup, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setFilterStatus, fetchCampaigns } from 'redux/features/campaignSlice/campaignSlice';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: "4px",
    },
    '&:first-of-type': {
      borderRadius: "4px",
    },
  },
}));

const FilterButtons = () => {
  const theme = useTheme()
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const handleFilterClick = (filterStatus: number) => {
    dispatch(setFilterStatus(filterStatus));
    dispatch(fetchCampaigns(filterStatus));  
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}
        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(1)}
      >
        ({t("marketingView.design")})
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(2)}
      >
                ({t("marketingView.inProgress")})
      </Button>
      <Button
        size="small"
        variant={theme.palette.mode === "light" ? "outlined" : "contained"}

        sx={{
          mb: "10px",
        }}
        onClick={() => handleFilterClick(3)}
      >
                ({t("marketingView.finished")})

      </Button>
    </Box>
  );
}

export default FilterButtons