import { useTranslation } from 'react-i18next';
// Hooks
import { useStepHandler } from 'hooks/useStepHandler';
// Components/ui
import { Stepper, Step, StepLabel, Box, Button } from '@mui/material';
import {
  CampaignTypeStep,
  CampaignNameStep,
  RecipientsStep,
  WritingStep,
} from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectCampaignName } from 'redux/features/createCampaignSlice/selectors';

const steps: string[] = ['campaignType', 'campaignName', 'recipients', 'drafting'];

const MainStepper: React.FC = () => {
  const { t } = useTranslation();

  const { activeStep, handleNext, handleBack, isLastStep } = useStepHandler();

  const campaignName = useSelector(selectCampaignName);

  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return <CampaignTypeStep handleNext={handleNext} />;
      case 1:
        return <CampaignNameStep />;
      case 2:
        return <RecipientsStep />;
      case 3:
        return <WritingStep />;
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white', padding: 2 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(`campaigns.${label}`)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          mt: 4,
          mb: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {getStepContent(activeStep)}
      </Box>
      <Box>
        <Button
          disabled={activeStep === 0 || activeStep === 2}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {t('global.back')}
        </Button>
        {
          <Button
            disabled={activeStep === 0 || (activeStep === 1 && campaignName === '')}
            variant="contained"
            onClick={handleNext}
          >
            {t(`global.${isLastStep ? 'finalize' : 'next'}`)}
          </Button>
        }
      </Box>
    </Box>
  );
};

export default MainStepper;
