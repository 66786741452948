// import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; // eslint-disable-line
import { Route, Routes as ReactRoutes, useNavigate } from 'react-router-dom';
/** Components */
import MainLayout from './features/Layout/MainLayout';
import { Dashboard } from 'features/Views/Dashboard';
import SystemMVC from 'features/Views/SystemMVC';
import MarketingView from 'features/Views/MarketingView';
import { useEffect } from 'react';
import NewCampaign from 'features/Views/NewCampaign';

const Routes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if current path is /a/marketing and redirect to /a/marketing/marketing
    if (window.location.pathname === '/a/marketing')
      navigate('/a/marketing/Marketing');
  }, [navigate]);

  return (
    <MainLayout>
      <ReactRoutes>
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
        <Route path="/a/marketing/dashboard" element={<Dashboard />} />
        <Route
          caseSensitive
          path="/a/marketing/Marketing"
          element={<MarketingView />}
        />
        <Route path="/a/marketing/reports" element={<></>} />
        <Route caseSensitive path="/a/marketing/System" element={<SystemMVC />} />
        <Route path="/a/marketing/newCampaign" element={<NewCampaign />} />
      </ReactRoutes>
    </MainLayout>
  );
};

export default Routes;
