import { useState } from 'react';
// Redux
import { useAppDispatch } from './useAppDispatch';
import {
  createCampaign,
  setCampaignType,
} from 'redux/features/createCampaignSlice/createCampaignSlice';
import { useSelector } from 'react-redux';
import {
  selectCampaignName,
  selectCampaignType,
} from 'redux/features/createCampaignSlice/selectors';

interface StepHandler {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  isLastStep: boolean;
}

export const useStepHandler = (): StepHandler => {
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const campaignName = useSelector(selectCampaignName);
  const campaignType = useSelector(selectCampaignType);

  const nextStep = () => {
    setActiveStep((prevStep) => (prevStep < 4 - 1 ? prevStep + 1 : prevStep));
  };

  const handleCreateCampaign = async () => {
    const response = await dispatch(
      createCampaign({ title: campaignName, type: campaignType })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      nextStep();
    }
  };

  const handleNext = () => {
    if (activeStep === 1) {
      handleCreateCampaign();
      return;
    }

    nextStep();
  };

  const handleBack = () => {
    if (activeStep === 1) dispatch(setCampaignType(null));

    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const isLastStep = activeStep === 4 - 1;

  return { activeStep, handleNext, handleBack, isLastStep };
};
