import { useContext } from 'react';
import { useState, useEffect } from 'react';
// Context
// import { ContactBusinessTableContext } from 'components/ContactsBusinessTable/context/ContactBusinessTableContext';
// Redux
import { useSelector } from 'react-redux';
// import { selectContactFields } from 'redux/features/contactsSlice/contactsSlice';
// Components/ui
import 
{ TableCell, 
  Button, 
  Menu, 
  MenuItem, 
  IconButton 
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';

// Components
import { ContactBusinessTableContext } from 'features/Views/MarketingView/context/ContactBusinessTableContext';
import CampaignStatusTag from './components/CampaignStatusTag';

// Types
// import type { IContactWithCheckbox } from 'redux/features/contactsSlice/types/IContactWithCheckBox';
// import type { IBusinessWithCheckbox } from 'redux/features/contactsSlice/types/IBusinessWithCheckBox';

// type Props = {
//   contact: IContactWithCheckbox | IBusinessWithCheckbox;
// };


const Cells = ({ campaign }) => {
  const [anchorEl, setAnchorEL] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEL(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEL(null);
  }

  const columnDialog = [
    {
      title: "Estado",
      visible: true,
      value: <CampaignStatusTag status={campaign.status} />
    },
    {
      title: "Nombre",
      visible: true,
      value: campaign.title

    },
    {
      title: "Destinatarios",
      visible: true,
      value: campaign.countersRecipients

    },
    {
      title: "Entregados",
      visible: true,
      value: campaign.countersContacts

    },
    {
      title: "Aperturas(%)",
      visible: true,
      value: campaign.countersOpenings

    },
    {
      title: "Clicks",
      visible: true,
      value: campaign.countersClicks

    },
    {
      title: "Fecha de envío",
      visible: true,
      value: campaign.createdAt ? new Date(campaign.createdAt).toLocaleDateString() : "Fecha no disponible"


    },
    {
      title: "Acciones",
      visible: true,
      value: (
        <>
          <Button variant='contained' size="small">Editar</Button>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '30ch',
              },
            }}
          >
            <MenuItem onClick={handleClose}>Reanudar/pausar</MenuItem>
            <MenuItem onClick={handleClose}>Editar</MenuItem>
            <MenuItem onClick={handleClose}>Duplicar</MenuItem>
            <MenuItem onClick={handleClose}>Eliminar</MenuItem>
          </Menu>
        </>
      )

    },
  ]

  // const columns = useSelector(selectContactFields);
  return (
    <>
      {columnDialog.map(
        (column, i) =>
          column.visible && (
            <TableCell key={i} sx={{ padding: '0 24px' }}>
              <span
                // onClick={() => handleContactClick(contact.id)}
                style={{ userSelect: 'none' }}
              >
                {column.value}
              </span>
            </TableCell>
          )
      )}
      <TableCell sx={{ padding: '0 24px' }}>

      </TableCell>
    </>
  );
};

export default Cells;
