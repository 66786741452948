import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { initRequestData } from '../../functions/initRequestData';
import { CampaignSliceState } from './types/CampaignSliceState';
// Service
import campaignSliceService from './campaignSliceService';
// Types
import InitRequestDataReturn from '../../types/InitRequestDataReturn';
import { ICampaign } from '@trii/types/dist/Campaigns';
import { RootState } from 'redux/store';


const initialState: CampaignSliceState = {
    campaigns: [],
    status: {
        campaigns: 'idle',
    },
    filterStatus: 1,
};


export const fetchCampaigns = createAsyncThunk<
    ICampaign[],
    number,
    { state: RootState }
>('Campaign/fetchCampaigns', async (filterStatus = 1, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData())).payload as InitRequestDataReturn;

    const campaigns = await campaignSliceService.fetchCampaigns(jwtToken, URL_MARKETING, filterStatus);

    return campaigns;
});

export const campaignSlice = createSlice({
    name: 'Campaign',
    initialState,
    reducers: {
        setCampaigns: (state, action: PayloadAction<ICampaign[]>) => {
            state.campaigns = action.payload;
        },
        setFilterStatus: (state, action: PayloadAction<number>) => {
            state.filterStatus = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCampaigns.pending, (state) => {
                state.status.campaigns = 'loading';
            })
            .addCase(fetchCampaigns.fulfilled, (state, action: PayloadAction<ICampaign[]>) => {
                state.status.campaigns = 'succeeded';
                state.campaigns = action.payload;
            })
            .addCase(fetchCampaigns.rejected, (state) => {
                state.status.campaigns = 'rejected';
            });
    },
});

// Selectores
const campaignState = (state: RootState) => state.Campaign;

export const selectCampaigns = createSelector(
    campaignState,
    (campaign) => campaign.campaigns
);

export const selectCampaignStatus = createSelector(
    campaignState,
    (campaign) => campaign.status
);

export const selectFilterStatus = createSelector(
    campaignState,
    (campaign) => campaign.filterStatus
);

export const { setFilterStatus } = campaignSlice.actions;


export default campaignSlice.reducer;
