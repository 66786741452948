// Components/ui
import { TextField, CircularProgress } from '@mui/material';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setCampaignName } from 'redux/features/createCampaignSlice/createCampaignSlice';
import {
  selectCampaignName,
  selectCreateCampaignStatus,
} from 'redux/features/createCampaignSlice/selectors';

const CampaignNameStep = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const campaignName = useSelector(selectCampaignName);
  const createCampaignState = useSelector(selectCreateCampaignStatus);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCampaignName(event.target.value));
  };

  if (createCampaignState === 'loading')
    return (
      <CircularProgress
        sx={{
          height: 64,
          width: 64,
        }}
      />
    );

  return (
    <TextField
      value={campaignName}
      onChange={handleNameChange}
      label={t('campaigns.campaignName')}
      variant="outlined"
      size="small"
      sx={{ width: '300px' }}
    />
  );
};

export default CampaignNameStep;
