import { useTranslation } from 'react-i18next';
// Components/ui
import { Box, Typography, styled } from '@mui/material';
import { MainStepper } from './components';

const NewCampaignContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: 16,
  padding: 16,
});

const NewCampaign = () => {
  const { t } = useTranslation();
  return (
    <NewCampaignContainer>
      <Typography variant="h4">{t('campaigns.newCampaign')}</Typography>
      <MainStepper />
    </NewCampaignContainer>
  );
};

export default NewCampaign;
