import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
// Types
import { CampaignSliceState, CreateCampaignData } from './types/index';
import { CampaignType, ICampaign } from '@trii/types/dist/Marketing';
import InitRequestDataReturn from 'redux/types/InitRequestDataReturn';
// Utils
import createCampaignService from './service';
import { initRequestData } from 'redux/functions/initRequestData';

const initialState: CampaignSliceState = {
  campaignType: null,
  campaignName: '',
  recipients: [],
  writing: '',
  createdCampaign: null,
  status: {
    create: 'idle',
  },
};

export const createCampaign = createAsyncThunk(
  'CreateCampaign/createCampaign',
  async (data: CreateCampaignData, { dispatch }) => {
    const { jwtToken, URL_MARKETING } = (await dispatch(initRequestData()))
      .payload as InitRequestDataReturn;

    try {
      const response = await createCampaignService.createCampaign(
        URL_MARKETING,
        jwtToken,
        data
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const createCampaignSlice = createSlice({
  name: 'CreateCampaign',
  initialState,
  reducers: {
    setCampaignType: (state, action: PayloadAction<CampaignType>) => {
      state.campaignType = action.payload;
    },
    setCampaignName: (state, action: PayloadAction<string>) => {
      state.campaignName = action.payload;
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    setWriting: (state, action) => {
      state.writing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCampaign.pending, (state, action) => {
      state.status.create = 'loading';
    });
    builder.addCase(
      createCampaign.fulfilled,
      (state, action: PayloadAction<ICampaign>) => {
        state.status.create = 'succeeded';
      }
    );
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.status.create = { error: 'Error' };
    });
  },
});

export const { setCampaignType, setCampaignName, setRecipients, setWriting } =
  createCampaignSlice.actions;

export default createCampaignSlice.reducer;
