import { RootState } from 'redux/rootReducer';

// Selectors
const campaignState = (state: RootState) => state.CreateCampaign;

export const selectCampaignType = (state: RootState) =>
  campaignState(state).campaignType;

export const selectCampaignName = (state: RootState) =>
  campaignState(state).campaignName;

export const selectRecipients = (state: RootState) =>
  campaignState(state).recipients;

export const selectWriting = (state: RootState) => campaignState(state).writing;

export const selectCreateCampaignStatus = (state: RootState) =>
  campaignState(state).status.create;
