import axios from 'axios';
import getRequestConfig from '../../functions/getRequestConfig';
import { ICampaign } from '@trii/types/dist/Campaigns';

const fetchCampaigns = async (
    jwtToken: string,
    URL: string,
    status:number
): Promise<ICampaign[]> => {
    const config = getRequestConfig.basic(jwtToken);  
    const response = await axios.get<ICampaign[]>(`${URL}/Campaigns?status=${status}`, config);  
    return response.data;
};


const campaignSliceService = {
    fetchCampaigns,  
};

export default campaignSliceService;
